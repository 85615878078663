import axiosInstance from '../../../plugin/axios'

export const frontendMessage = {
  namespaced: true,
  state: {
    lists: [],
    page: {},
    pagination: [],
    show: {},
    temp: {
      temp_id: null,
      isEditing: false
    },
    id: ''
  },
  getters: {
    lists: function (state) {
      return state.lists
    }
  },
  actions: {
    lists: function (context, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`frontend/message`, {
            params: payload
          })
          .then((res) => {
            context.commit('lists', res.data.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    save: function (context, payload) {
      return new Promise((resolve, reject) => {
        let method = axiosInstance.post
        method('/frontend/message', payload)
          .then((res) => {
            context.commit('lists', {
              branch_id: payload.branch_id,
              user_id: payload.user_id
            })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    reset: function (context) {
      context.commit('reset')
    }
  },
  mutations: {
    lists: function (state, payload) {
      state.lists = payload
    },
    reset: function (state) {
      state.temp.temp_id = null
      state.temp.isEditing = false
    }
  }
}
