<template>
  <LoadingComponent :props="loading" />

  <!--========TRACK PART START===========-->
  <TrackOrderComponent />
  <!--========TRACK PART END=============-->

  <!--========BANNER PART START===========-->
  <SliderComponent />
  <!--========BANNER PART END=============-->

  <!--========Category PART START=========-->
  <section v-if="categories.length > 0" class="mb-6 sm:mb-12">
    <div class="container">
      <div class="flex items-center justify-between gap-2 mb-3 sm:mb-6 sm:mt-4">
        <h2 class="text-lg sm:text-2xl font-semibold capitalize">{{ $t('label.our_menu') }}</h2>
        <router-link
          :to="{ name: 'frontend.menu', query: { s: categoryProps.slug } }"
          class="rounded-3xl capitalize text-xs sm:text-sm leading-6 font-medium py-0 sm:py-1 px-2 sm:px-3 transition text-primary bg-[#D8FFFC] hover:text-white hover:bg-primary"
        >
          {{ $t('button.view_all') }}
        </router-link>
      </div>
      <div class="swiper menu-swiper">
        <CategoryComponent :categories="categories" :design="categoryProps.design" />
      </div>
    </div>
  </section>
  <!--========Category PART END===========-->

  <!--========FEATURE PART START=========-->
  <FeaturedItemComponent />
  <!--========FEATURE PART END=========-->

  <!--========OFFER PART START=========-->
  <OfferComponent :limit="limit" />
  <!--========OFFER PART START=========-->

  <!--========POPULAR PART START=========-->
  <PopularItemComponent />
  <!--========POPULAR PART START=========-->
</template>

<script>
import SliderComponent from '../../frontend/home/SliderComponent.vue'
import CategoryComponent from '../components/CategoryComponent.vue'
import FeaturedItemComponent from '../home/FeaturedItemComponent.vue'
import PopularItemComponent from '../home/PopularItemComponent.vue'
import OfferComponent from '../components/OfferComponent.vue'
import categoryDesignEnum from '../../../enums/modules/categoryDesignEnum'
import statusEnum from '../../../enums/modules/statusEnum'
import LoadingComponent from '../components/LoadingComponent.vue'
import TrackOrderComponent from './TrackOrderComponent.vue'

export default {
  name: 'HomeComponent',
  components: {
    TrackOrderComponent,
    CategoryComponent,
    SliderComponent,
    FeaturedItemComponent,
    PopularItemComponent,
    OfferComponent,
    LoadingComponent
  },
  data() {
    return {
      loading: {
        isActive: false
      },
      categoryProps: {
        design: categoryDesignEnum.FIRST,
        slug: ''
      },
      limit: 4
    }
  },
  computed: {
    categories: function () {
      return this.$store.getters['frontendItemCategory/lists']
    }
  },
  mounted() {
    this.loading.isActive = true
    this.$store
      .dispatch('frontendItemCategory/lists', {
        paginate: 0,
        order_column: 'id',
        order_type: 'asc',
        status: statusEnum.ACTIVE
      })
      .then((_res) => {
        this.loading.isActive = false
      })
      .catch((_err) => {
        this.loading.isActive = false
      })
  },
  watch: {
    categories: {
      deep: true,
      handler(category) {
        if (category.length > 0) {
          if (category[0].slug !== 'undefined') {
            this.categoryProps.slug = category[0].slug
          }
        }
      }
    }
  }
}
</script>
