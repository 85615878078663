import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import { auth } from './modules/frontend/frontendAuth'
import { frontendBranch } from './modules/frontend/frontendBranch'
import { frontendLanguage } from './modules/frontend/frontendLanguage'
import { frontendSetting } from './modules/frontend/frontendSetting'
import { frontendPage } from './modules/frontend/frontendPage'
import { globalState } from './modules/frontend/globalState'
import { frontendSlider } from './modules/frontend/frontendSlider'
import { frontendItemCategory } from './modules/frontend/frontendItemCategory'
import { frontendCart } from './modules/frontend/frontendCart'
import { frontendEditProfile } from './modules/frontend/frontendEditProfile'
import { frontendCountryCode } from './modules/frontend/frontendCountryCode'
import { frontendAddress } from './modules/frontend/frontendAddress'
import { frontendTimeSlot } from './modules/frontend/frontendTimeSlot'
import { frontendItem } from './modules/frontend/frontendItem'
import { frontendOffer } from './modules/frontend/frontendOffer'
import { frontendCoupon } from './modules/frontend/frontendCoupon'
import { frontendOrder } from './modules/frontend/frontendOrder'
import { frontendSignup } from './modules/frontend/frontendSignup'
import { GuestSignup } from './modules/frontend/GuestSignup'
import { frontendMessage } from './modules/frontend/frontendMessage'

export default new createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    globalState,
    frontendBranch,
    frontendLanguage,
    frontendSetting,
    frontendPage,
    frontendSlider,
    frontendItemCategory,
    frontendCart,
    frontendEditProfile,
    frontendCountryCode,
    frontendAddress,
    frontendTimeSlot,
    frontendItem,
    frontendOffer,
    frontendCoupon,
    frontendOrder,
    frontendSignup,
    GuestSignup,
    frontendMessage
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'globalState', 'frontendCart', 'frontendSignup', 'GuestSignup', 'posCart']
    })
  ]
})
