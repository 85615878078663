import { createRouter, createWebHistory } from 'vue-router'
import appService from '../services/appService'
import NotFoundComponent from '../components/frontend/otherPage/NotFoundComponent.vue'
import ExceptionComponent from '../components/frontend/otherPage/ExceptionComponent.vue'
import store from '../store'
import authRoutes from './modules/authRoutes'
import frontendRoutes from './modules/frontendRoutes'

const baseRoutes = [
  {
    path: '/',
    redirect: { name: 'frontend.home' },
    name: 'root'
  },
  {
    path: '/index.html',
    redirect: { name: 'frontend.home' },
    name: 'root.index'
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'route.notFound',
    component: NotFoundComponent,
    meta: {
      isFrontend: true
    }
  },
  {
    path: '/exception',
    name: 'route.exception',
    component: ExceptionComponent
  }
]

const routes = baseRoutes.concat(frontendRoutes, authRoutes)

const permission = store.getters.authPermission
appService.recursiveRouter(routes, permission)

const router = createRouter({
  linkActiveClass: 'active',
  mode: 'history',
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth === true) {
    if (!store.getters.authStatus) {
      next({ name: 'auth.login' })
    } else {
      if (to.meta.isFrontend === false) {
        if (to.meta.access === false) {
          next({
            name: 'route.exception'
          })
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else if (to.name === 'auth.login' && store.getters.authStatus) {
    next({ name: 'frontend.home' })
  } else {
    next()
  }
})
export default router
