import { createI18n } from 'vue-i18n'

async function loadMessages() {
  const files = import.meta.glob('./languages/*.json')

  const messages = await Promise.all(
    Object.keys(files).map(async (path) => {
      const localeMatch = path.match(/\.\/languages\/([a-z0-9-_]+)\.json/i)
      if (!localeMatch) return null

      const locale = localeMatch[1]
      const module = await files[path]()
      return { locale, module }
    })
  )

  const messagesObject = messages.reduce((acc, { locale, module }) => {
    if (locale) acc[locale] = module.default
    return acc
  }, {})

  return { messages: messagesObject }
}

const { messages } = await loadMessages()

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: messages
})

export default i18n
