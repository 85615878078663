import axiosInstance from '../../../plugin/axios'
import appService from '../../../services/appService'

export const frontendSlider = {
  namespaced: true,
  state: {
    lists: []
  },
  getters: {
    lists: function (state) {
      return state.lists
    }
  },
  actions: {
    lists: function (context, payload) {
      return new Promise((resolve, reject) => {
        let url = 'frontend/slider'
        if (payload) {
          url = url + appService.requestHandler(payload)
        }
        axiosInstance
          .get(url)
          .then((res) => {
            context.commit('lists', res.data.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  mutations: {
    lists: function (state, payload) {
      state.lists = payload
    }
  }
}
