import axios from 'axios'
import ENV from '../config/env'

/* Start axios code*/
const API_URL = ENV.API_URL

const axiosInstance = axios.create({
  baseURL: API_URL + '/api'
})

export default axiosInstance
