<template>
  <div class="w-full max-w-[630px] rounded-2xl bg-white">
    <div class="felx-1 w-full mb-4" v-if="setting.autocomplete">
      <autocomplete
        :search="searchAddress"
        v-if="setting.autocomplete"
        id="map-autocomplete-input"
        class="autocomplete"
        :debounce-time="500"
        :submitOnEnter="true"
        placeholder="Cari lokasi"
        :get-result-value="getResultValue"
        @submit="selectAddress"
      >
        <template #result="{ result, props }">
          <li v-bind="props">
            <div class="wiki-title">
              {{ result.display_name }}
            </div>
          </li>
        </template>
      </autocomplete>
    </div>
    <div class="w-full h-[180px] rounded-xl mb-4">
      <div id="map" class="w-full h-full"></div>
    </div>
  </div>
</template>

<style>
.autocomplete-result-list {
  z-index: 10000 !important;
}
</style>

<script>
import LoadingContentComponent from './LoadingContentComponent.vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import _ from 'lodash'
import '../../../assets/leaftlet.css'
import leaflet from 'leaflet'

export default {
  name: 'MapComponent',
  components: { LoadingContentComponent, Autocomplete },
  props: {
    location: Object,
    position: Function,
    setting: {
      type: Object,
      default: {
        autocomplete: {
          type: Boolean,
          default: true,
          required: false
        },
        mouseEvent: {
          type: Boolean,
          default: true,
          required: false
        },
        currentLocation: {
          type: Boolean,
          default: true,
          required: false
        }
      }
    }
  },
  data() {
    return {
      zoom: 13,
      currentLocation: {
        lat: null,
        lng: null
      },
      map: null,
      address: null,
      userMarker: null,
      markerId: null,
      markerGroup: null
    }
  },
  mounted() {
    let initLatLng = [-6.804961196073049, 110.8417343834219]
    if (this.location.lat && this.location.lng) {
      initLatLng = [this.location.lat, this.location.lng]
      this.setPosition(this.location.lat, this.location.lng)
    }

    this.map = leaflet.map('map').setView(initLatLng, 13)

    leaflet
      .tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: ''
      })
      .addTo(this.map)

    this.map.addEventListener('click', (e) => {
      const { lat: latitude, lng: longitude } = e.latlng
      this.setPosition(latitude, longitude)
    })

    this.markerGroup = leaflet.layerGroup().addTo(this.map)
  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove()
    }
  },
  methods: {
    setPosition: async function (latitude, longitude) {
      this.currentLocation.lat = latitude
      this.currentLocation.lng = longitude

      this.map.panTo({
        lat: latitude,
        lon: longitude
      })

      if (this.markerId) {
        this.markerGroup.removeLayer(this.markerId)
      }

      const icon = leaflet.icon({
        iconUrl: '/img/marker.png',
        iconSize: [24, 24]
      })

      this.markerId = leaflet.marker([latitude, longitude], { icon: icon }).addTo(this.markerGroup)

      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&accept-language=id`
      )
      const results = await response.json()
      if (results) {
        let other = {
          rodeNo: null,
          block: null,
          area: results.address.village,
          city: results.address.city,
          zipCode: results.address.postcode,
          state: results.address.state,
          country: results.address.country
        }
        this.address = results.display_name
        this.position({
          address: results.display_name,
          other: other,
          location: this.currentLocation
        })
      }
    },
    async searchAddress(input) {
      if (input.length < 1) {
        return []
      }

      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&accept-language=id&countrycodes=ID&q=${input}`
      )
      const results = await response.json()

      return results
    },
    selectAddress(result) {
      this.setPosition(parseFloat(result.lat), parseFloat(result.lon))
      this.address = result.display_name
    },
    getResultValue(result) {
      return result.display_name
    }
  }
}
</script>
