<template>
  <h2 class="text-[22px] leading-[34px] font-medium capitalize mb-3">
    {{ $t('label.our_branches') }}
  </h2>
  <div class="row mb-7">
    <div class="col-12 sm:col-6 mb-2" v-if="branches.length > 0" v-for="branch in branches">
      <div class="flex items-center gap-2 mb-3">
        <span class="w-6 h-6 rounded-full flex items-center justify-center bg-[#BDEFFF]">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.18 4.135L11.035 2.75C10.825 1.24 10.14 0.625 8.67501 0.625H7.49501H6.75501H5.23501H4.49501H3.29501C1.82501 0.625 1.14501 1.24 0.930008 2.765L0.795008 4.14C0.745008 4.675 0.890008 5.195 1.20501 5.6C1.58501 6.095 2.17001 6.375 2.82001 6.375C3.45001 6.375 4.05501 6.06 4.43501 5.555C4.77501 6.06 5.35501 6.375 6.00001 6.375C6.64501 6.375 7.21001 6.075 7.55501 5.575C7.94001 6.07 8.53501 6.375 9.15501 6.375C9.82001 6.375 10.42 6.08 10.795 5.56C11.095 5.16 11.23 4.655 11.18 4.135Z"
              fill="#008BBA"
            />
            <path
              d="M5.675 8.33012C5.04 8.39512 4.56 8.93512 4.56 9.57512V10.9451C4.56 11.0801 4.67 11.1901 4.805 11.1901H7.19C7.325 11.1901 7.435 11.0801 7.435 10.9451V9.75012C7.44 8.70512 6.825 8.21012 5.675 8.33012Z"
              fill="#008BBA"
            />
            <path
              d="M10.685 7.20004V8.69004C10.685 10.07 9.565 11.19 8.185 11.19C8.05 11.19 7.94 11.08 7.94 10.945V9.75004C7.94 9.11004 7.745 8.61004 7.365 8.27004C7.03 7.96504 6.575 7.81504 6.01 7.81504C5.885 7.81504 5.76 7.82004 5.625 7.83504C4.735 7.92504 4.06 8.67504 4.06 9.57504V10.945C4.06 11.08 3.95 11.19 3.815 11.19C2.435 11.19 1.315 10.07 1.315 8.69004V7.21004C1.315 6.86004 1.66 6.62504 1.985 6.74004C2.12 6.78504 2.255 6.82004 2.395 6.84004C2.455 6.85004 2.52 6.86004 2.58 6.86004C2.66 6.87004 2.74 6.87504 2.82 6.87504C3.4 6.87504 3.97 6.66004 4.42 6.29004C4.85 6.66004 5.41 6.87504 6 6.87504C6.595 6.87504 7.145 6.67004 7.575 6.30004C8.025 6.66504 8.585 6.87504 9.155 6.87504C9.245 6.87504 9.335 6.87004 9.42 6.86004C9.48 6.85504 9.535 6.85004 9.59 6.84004C9.745 6.82004 9.885 6.77504 10.025 6.73004C10.35 6.62004 10.685 6.86004 10.685 7.20004Z"
              fill="#008BBA"
            />
          </svg>
        </span>
        <h3 class="font-medium leading-6">{{ branch.name }}</h3>
      </div>
      <ul class="flex flex-col gap-2">
        <li class="flex items-center gap-2.5">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00001 8.95297C9.14877 8.95297 10.08 8.02172 10.08 6.87297C10.08 5.72422 9.14877 4.79297 8.00001 4.79297C6.85126 4.79297 5.92001 5.72422 5.92001 6.87297C5.92001 8.02172 6.85126 8.95297 8.00001 8.95297Z"
              stroke="#6E7191"
              stroke-width="1.5"
            />
            <path
              d="M2.41333 5.65968C3.72667 -0.113657 12.28 -0.106991 13.5867 5.66634C14.3533 9.05301 12.2467 11.9197 10.4 13.693C9.06 14.9863 6.94 14.9863 5.59333 13.693C3.75333 11.9197 1.64667 9.04634 2.41333 5.65968Z"
              stroke="#6E7191"
              stroke-width="1.5"
            />
          </svg>
          <span class="text-sm leading-6 text-heading">{{ branch.address }}</span>
        </li>
        <li class="flex items-center gap-2.5">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6467 12.2197C14.6467 12.4597 14.5933 12.7063 14.48 12.9463C14.3667 13.1863 14.22 13.413 14.0267 13.6263C13.7 13.9863 13.34 14.2463 12.9333 14.413C12.5333 14.5797 12.1 14.6663 11.6333 14.6663C10.9533 14.6663 10.2267 14.5063 9.46001 14.1797C8.69334 13.853 7.92668 13.413 7.16668 12.8597C6.40001 12.2997 5.67334 11.6797 4.98001 10.993C4.29334 10.2997 3.67334 9.57301 3.12001 8.81301C2.57334 8.05301 2.13334 7.29301 1.81334 6.53967C1.49334 5.77967 1.33334 5.05301 1.33334 4.35967C1.33334 3.90634 1.41334 3.47301 1.57334 3.07301C1.73334 2.66634 1.98668 2.29301 2.34001 1.95967C2.76668 1.53967 3.23334 1.33301 3.72668 1.33301C3.91334 1.33301 4.10001 1.37301 4.26668 1.45301C4.44001 1.53301 4.59334 1.65301 4.71334 1.82634L6.26001 4.00634C6.38001 4.17301 6.46668 4.32634 6.52668 4.47301C6.58668 4.61301 6.62001 4.75301 6.62001 4.87967C6.62001 5.03967 6.57334 5.19967 6.48001 5.35301C6.39334 5.50634 6.26668 5.66634 6.10668 5.82634L5.60001 6.35301C5.52668 6.42634 5.49334 6.51301 5.49334 6.61967C5.49334 6.67301 5.50001 6.71967 5.51334 6.77301C5.53334 6.82634 5.55334 6.86634 5.56668 6.90634C5.68668 7.12634 5.89334 7.41301 6.18668 7.75967C6.48668 8.10634 6.80668 8.45967 7.15334 8.81301C7.51334 9.16634 7.86001 9.49301 8.21334 9.79301C8.56001 10.0863 8.84668 10.2863 9.07334 10.4063C9.10668 10.4197 9.14668 10.4397 9.19334 10.4597C9.24668 10.4797 9.30001 10.4863 9.36001 10.4863C9.47334 10.4863 9.56001 10.4463 9.63334 10.373L10.14 9.87301C10.3067 9.70634 10.4667 9.57967 10.62 9.49967C10.7733 9.40634 10.9267 9.35967 11.0933 9.35967C11.22 9.35967 11.3533 9.38634 11.5 9.44634C11.6467 9.50634 11.8 9.59301 11.9667 9.70634L14.1733 11.273C14.3467 11.393 14.4667 11.533 14.54 11.6997C14.6067 11.8663 14.6467 12.033 14.6467 12.2197Z"
              stroke="#6E7191"
              stroke-width="1.5"
              stroke-miterlimit="10"
            />
            <path
              d="M12.3333 5.99935C12.3333 5.59935 12.02 4.98602 11.5533 4.48602C11.1267 4.02602 10.56 3.66602 10 3.66602"
              stroke="#6E7191"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.6667 5.99967C14.6667 3.41967 12.58 1.33301 10 1.33301"
              stroke="#6E7191"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="text-sm leading-6 text-heading">{{ branch.phone }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUsComponent',
  computed: {
    branches: function () {
      return this.$store.getters['frontendBranch/lists']
    }
  }
}
</script>
